#sidebar
	overflow-y: auto
	display: flex
	flex-direction: column
	height: 100vh
	width: 16rem
	padding: $gap
	position: fixed
	overflow: visible
	@include transition()

	#navigation
		height: 100%
		margin-block-start: $gap
		padding-block-end: $gap-lg
		color: $secondary
		gap: unset

		li
			&:last-child
				margin-block-start: auto
				display: flex

	.logo
		display: flex
		place-content: center
		margin-block: $gap

		.logo-full
			display: block
		.logo-icon
			display: none

		img
			height: 48px

	.user-info-group
		position: relative
		display: flex
		gap: $gap
		place-items: center
		padding: $gap
		background-color: $white
		border-radius: $border-radius

		.user
			.info
				.sub-title
					word-wrap: anywhere

		.info
			width: 100%
			font-weight: 600

	.nav
		display: flex
		flex-direction: column
		gap: .5rem

		.nav-link-text
			font-weight: 700
	
	.dropdown
		position: relative
		width: 100%

		.dropdown-menu
			position: absolute
			display: flex
			width: 100%
			left: 0
			padding-block-end: $gap
			border-top-left-radius: unset
			border-top-right-radius: unset

#sidebar-toggle
	display: none
	margin-inline-end: $gap
	border-radius: 100%
	width: 48px
	height: 48px
	place-items: center
	place-content: center