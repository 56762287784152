img
	width: 100%

.avatar
	display: block
	background-color: $black-25
	border-radius: 100%

.avatar-sm
	max-width: 40px
	width: 40px
	height: 40px

.avatar-xl
	max-width: 128px
	width: 128px
	height: 128px