#page-users
	.card-user
		flex-direction: row
		gap: $gap
		margin-block-end: unset

		.user-info-group
			flex-direction: row
			place-items: center

#page-user-create
	.card
		width: fit-content
		margin-inline: auto
		padding: unset
	.grid-2
		max-width: 1000px
		width: 100%
		margin-inline: auto

#page-settings
	.card
		width: fit-content
		margin-inline: auto
		padding: unset