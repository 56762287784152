.card
	padding: $gap
	border: unset
	margin-block-end: $gap
	background-color: $bg-complement

.card-login
	gap: $gap
	padding: $gap-lg

.card-user
	flex-direction: row
	gap: $gap

	button,
	.btn
		height: fit-content

	.avatar-container
		display: flex
		flex-direction: column
		place-items: center
		place-content: center
		gap: $gap

	// .user
	// 	display: flex
	// 	justify-content: space-between
	// 	place-items: center
	// 	width: 100%
	// 	.title,
	// 	p
	// 		margin: unset
	// 	.btn
	// 		height: fit-content

.card-image
	flex-direction: row
	padding: unset

	.card-image-container
		min-width: 512px
		width: 512px
		height: auto
		background-position: center
		background-size: cover
		border-top-left-radius: $border-radius
		border-bottom-left-radius: $border-radius

		.card-image-container-contents
			display: flex
			flex-direction: column
			place-items: center
			place-content: flex-end
			gap: $gap-sm
			width: 100%
			height: 100%
			padding: $gap-lg