table
	background-color: $white
	border-radius: $border-radius

	thead
		color: $secondary
		tr
			border-top-left-radius: $border-radius
			border-top-right-radius: $border-radius

	tbody
		tr
			@include transition()

.table-hover > tbody > tr:hover > *
	@include transition()

.table > :not(caption) > * > *
	//border-bottom-color: $black-25
	// figure out the bottom border color for tables

.table-legend
	display: flex
	gap: $gap

	.legend
		display: flex
		place-items: center
		gap: $gap-sm

		.preview
			width: 16px
			height: 16px
			border-radius: $border-radius-sm