body
	display: flex
	height: 100vh
	background: $body-bg
	color: $body-color
	scroll-behavior: smooth
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

h1, h2, h3, h4, h5, h6,
span, p, li, label, strong, small,
img, svg,
.navbar-brand, .nav-link
	user-select: none
	
img, svg
	pointer-events: none

a,
.nav-link
	text-decoration: unset
	@include transition()
	&:hover

ul
	margin: unset