.title,
.page-title,
.form-title
	font-weight: bold

.page-title
	display: flex
	margin-block-end: $gap

.form-title
	text-align: center

.sub-title
	font-weight: lighter