.navbar
	margin-block: $gap-lg
	border-radius: $border-radius
	background-color: $bg-complement

.navbar-collapse
	flex-grow: unset
	place-self: flex-end

	.navbar-nav
		gap: $gap

.nav-link
	color: $secondary
	cursor: pointer

	&.active
		color: $primary