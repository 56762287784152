.animate-spin
	animation-name: spin
	animation-duration: 2s
	animation-iteration-count: infinite
	animation-timing-function: linear

@keyframes spin
	0%
		transform: rotate(0deg)
	to
		transform: rotate(1turn)