@mixin grid($columns, $gap)
	display: grid
	gap: $gap
	grid-template-columns: repeat($columns, 1fr)
	margin-block-end: $gap

@mixin drop-shadow()
	filter: drop-shadow(0 $gap-sm $gap-sm $black-25)

@mixin transition()
	transition: all .15s ease-in-out