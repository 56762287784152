
.trash-can
	background: #fff
	display: inline-block
	margin: 0 auto
	position: relative
	-webkit-border-bottom-right-radius: 6px
	-webkit-border-bottom-left-radius: 6px
	-moz-border-radius-bottomright: 6px
	-moz-border-radius-bottomleft: 6px
	border-bottom-right-radius: 6px
	border-bottom-left-radius: 6px

	span
		position: absolute
		height: 12px
		background: #fff
		top: -19px
		left: -10px
		right: -10px
		border-top-left-radius: 10px
		border-top-right-radius: 10px
		transform: rotate(0deg)
		transition: transform 250ms
		transform-origin: 19% 100%

		&:after
			content: ''
			position: absolute
			width: 27px
			height: 7px
			background: #fff
			top: -10px
			border-top-left-radius: 10px
			border-top-right-radius: 10px
			transform: rotate(0deg)
			transition: transform 250ms
			transform-origin: 19% 100%
			left: 27px

	i
		position: relative
		width: 5px
		height: 50px
		background: #f43f5e
		display: block
		margin: 14px auto
		border-radius: 5px

		&:after
			content: ''
			width: 5px
			height: 50px
			background: #f43f5e
			position: absolute
			left: -18px
			border-radius: 5px

		&:before
			content: ''
			width: 5px
			height: 50px
			background: #f43f5e
			position: absolute
			right: -18px
			border-radius: 5px

	&:hover span
		transform: rotate(-45deg)
		transition: transform 250ms