#root
	width: 100vw
	height: 100vh
	overflow-x: auto

.wrapper
	position: relative
	display: flex
	min-height: 100vh

#content
	width: 100vw
	margin-inline-start: 16rem
	padding: $gap $gap-lg
	@include transition()

.page
	display: flex
	flex-direction: column