.fieldset-year
	position: relative

	.dropdown
		position: absolute
		background: white
		width: 100%
		border-bottom-right-radius: $border-radius
		border-bottom-left-radius: $border-radius
		@include transition
		@include drop-shadow

		.hide
			opacity: 1 !important
			pointer-events: auto !important

		.show
			opacity: 0 !important
			pointer-events: none !important

		&:hover 
			border-bottom-right-radius: $border-radius
			border-bottom-left-radius: $border-radius

		button:last-child
			border-bottom-right-radius: $border-radius
			border-bottom-left-radius: $border-radius

			&:hover 
				border-bottom-right-radius: $border-radius
				border-bottom-left-radius: $border-radius