@import 'bootstrap/scss/functions'

$brand: #fff

// Bootstrap
$body-color: #171717
$body-bg: #F3F4F6
$min-contrast-ratio: 1.5
$enable-shadows: true
$enable-reduced-motion: false
$secondary: #64748B
$light: #f5f5f5
$primary: #8540F5
$info: #0EA5E9
$success: #10B981
$warning: #F97316
$danger: #F43F5E
$width-content: 1200px
$border-radius: .5rem
$border-radius-sm: .25rem

// Gaps & Padding
$gap-xs: .25rem
$gap-sm: .5rem
$gap: 1rem
$gap-lg: 3rem
$gap-xl: 5rem
$gap-icon: .5rem

// Required
@import 'bootstrap/scss/variables'
@import 'bootstrap/scss/mixins'
@import 'bootstrap/scss/root'
@import 'bootstrap/scss/bootstrap'

//$bg-complement: $accordion-button-active-bg
$bg-complement: $white
$white-25: transparentize($white, .75)
$black-25: transparentize($black, .75)