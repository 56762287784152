.grid-1
	@include grid(1, $gap)
.grid-2
	@include grid(2, $gap)
.grid-3
	@include grid(3, $gap)
.grid-4
	@include grid(4, $gap)
.grid-5
	@include grid(5, $gap)
.grid-6
	@include grid(6, $gap)
.grid-7
	@include grid(7, $gap)
.grid-8
	@include grid(8, $gap)
.grid-center
	place-items: center
	place-content: center