.cover-login,
.cover-loading
	display: flex
	flex-direction: column
	place-self: center
	max-width: 500px
	width: 100%
	margin-inline: auto
	padding-inline: $gap

.cover-login
	.cover-login-logo-container
		display: block
		width: fit-content
		margin-inline: auto

		.cover-login-logo
			height: 7.5rem
			width: auto
			margin-block-end: $gap
			padding: $gap

.cover-loading
	place-content: center
	height: 100vh