.bg-gray-300
	background-color: $gray-300
.bg-teal-300
	background-color: $teal-300
.bg-orange-300
	background-color: $orange-300
.bg-red-300
	background-color: $red-300
.bg-cyan-300
	background-color: $cyan-300
.bg-red-500
	background-color: $red-500