footer
	position: relative
	display: flex
	flex-direction: column
	place-content: center
	place-items: center
	width: 100%
	bottom: 0
	min-height: 25vh
	margin-block-start: $gap-xl
	padding: $gap-lg
	background-color: $bg-complement

	.grid
		max-width: $width-content
		@include grid(3, $gap-lg)
		margin-block-end: unset

		.section
			display: flex
			place-items: center
			flex-direction: column
			strong
				font-size: 1.5em

			p,
			strong
				text-align: center

			.socials
				display: flex
				place-content: center
				gap: $gap
				margin-block-end: $gap
				svg
					font-size: 2em

	.nav .nav-link,
	ul
		padding-inline: unset

	ul
		list-style: none

		li
			padding-block: $gap-sm
			color: $primary

	.address
		display: inline-flex
		li
			padding-block: unset

	.copyright
		color: $secondary