.btn
	display: flex
	place-items: center
	width: fit-content
	box-shadow: unset

	&:hover,
	&:focus,
	&:active,
	&:active:focus
		box-shadow: unset

.table-options
	display: flex
	place-items: center
	gap: $gap-sm

	.form-group
		margin: unset

.btn-forklift-data
	height: fit-content
	place-self: center