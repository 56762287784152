form
	width: 100%

.form-forklift,
.form-user
	padding: $gap-lg
	
.form-group
	max-width: 500px
	margin-block-end: $gap

.user-info-group
	display: flex
	flex-direction: column
	place-content: center
	gap: $gap
	width: 100%

	label,
	.form-label
		margin: unset

	.user
		display: flex
		place-items: center
		gap: $gap
		width: 100%

		.info
			display: flex
			flex-direction: column

			small
				color: $secondary
			
label,
.form-label
	text-align: center
	font-weight: bold
	color: $secondary
	width: fit-content

input,
.form-control
	box-shadow: unset

	&:hover,
	&:focus,
	&:active,
	&:active:focus
		box-shadow: unset

input[readonly],
.form-control[readonly]
	padding: unset
	border: unset
	outline: unset

.form-check .form-check-input.disabled,
.form-check .form-check-input[disabled],
.form-check .form-check-input.disabled ~ .form-check-label,
.form-check .form-check-input[disabled] ~ .form-check-label
	cursor: default

.form-check .form-check-input,
.form-check .form-check-input ~ .form-check-label,
.form-switch-group .form-label,
.form-switch-group .form-switch .form-check-input
	cursor: pointer

//.form-check .form-check-label,

.form-check
	display: flex
	place-items: center
	gap: $gap
	width: fit-content
	margin-bottom: unset

.form-check-input,
.form-switch
	box-shadow: unset

	&:hover,
	&:focus,
	&:active,
	&:active:focus
		box-shadow: unset

.form-switch-group
	padding-inline: $gap-sm
	.form-label
		margin: unset
	.form-switch
		font-size: 125%
		.form-check-input
			@include transition()

.invalid-input
	color: $red-500