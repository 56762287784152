@media only screen and (max-width: 1700px)

	#page-users
		.grid-4
			grid-template-columns: repeat(3, 1fr)

	#page-forklift
		.card-image
			flex-direction: column
			.card-image-container
				height: 400px
				width: 100%
				max-width: 400px
				min-width: unset
				margin: $gap auto 0
				border-radius: $border-radius
				.card-image-container-contents
					padding: $gap

@media only screen and (max-width: 1400px)

	#sidebar
		padding-inline-end: unset
		width: 8rem
		.logo
			.logo-full
				display: none
			.logo-icon
				display: block

		.user-info-group
			.user
				place-content: center
				.info
					display: none

		#navigation
			.nav-item
				width: 100%
				.nav-link
					display: flex
					flex-direction: column
					place-items: center
					padding-inline: unset
					width: 100%
					.nav-link-text

	#content
		margin-inline-start: 8rem
		padding: $gap

	table
		.table-options,
		.btn-group,
		.btn-group-vertical
			button,
			.btn
				padding-inline: $gap-sm
				svg
					margin: unset
				span
					display: none

	#table-button-options-forklifts
		flex-direction: column
		place-items: unset


@media only screen and (max-width: 1200px)

	#sidebar
		z-index: 999999
		background-color: $body-bg
		width: 16rem
		transform: translateX(-16rem)
		border-top-right-radius: $gap
		border-bottom-right-radius: $gap

		.logo
			.logo-full
				display: block
			.logo-icon
				display: none

		.user-info-group
			.user
				place-content: unset
				.info
					display: flex

		#navigation
			.nav-item
				.nav-link
					flex-direction: row
					place-items: unset
					padding-inline: $gap
					.nav-link-text

	#sidebar-toggle
		display: flex

	#content
		margin-inline-start: unset

	#page-forklift
		.form-forklift
			.grid-5
				grid-template-columns: repeat(4, 1fr)

@media only screen and (max-width: 992px)
	.grid-6
		@include grid(1, $gap)
		
	#page-users
		.grid-4
			grid-template-columns: repeat(2, 1fr)
	
	#page-user-create
		.grid-2
			grid-template-columns: repeat(1, 1fr)
	
	#page-settings
		max-width: 400px
		width: 100%
		margin-inline: auto
		.grid-3
			grid-template-columns: repeat(1, 1fr)


	#page-forklift
		.form-forklift
			.grid-3
				grid-template-columns: repeat(2, 1fr)
			.grid-4
				grid-template-columns: repeat(2, 1fr)
			.grid-5
				grid-template-columns: repeat(2, 1fr)
			.table-options
				flex-direction: column
				.btn
					margin-inline: auto

	#table-legend-forklifts
		@include grid(2, $gap)
		margin: unset

	footer
		.grid
			grid-template-columns: 1fr
			.section
				max-width: calc($width-content / 2)
				margin-inline: auto
				&:nth-child(2)
					order: 1
				&:first-child
					order: 2
				&:last-child
					order: 3

@media only screen and (max-width: 768px)
	.grid-6
		@include grid(1, $gap)

	#page-users
		.grid-4
			grid-template-columns: repeat(1, 1fr)

			.card-user
				width: 100%
				max-width: 400px
				margin-inline: auto

	#page-forklift
		.form-forklift
			.grid-5,
			.grid-4,
			.grid-3,
			.grid-1
				place-items: center
				max-width: 250px
				width: 100%
				margin-inline: auto
				.form-group
					width: 100%
			.grid-3
				grid-template-columns: repeat(1, 1fr)
			.grid-4,
			.grid-5
				grid-template-columns: repeat(1, 1fr)
				place-items: center

	.flex-row
		flex-direction: column !important

@media only screen and (max-width: 576px)

	#page-forklift
		.card-image
			flex-direction: column
			.card-image-container
				max-width: unset
				min-width: unset
				margin: unset
				border-bottom-left-radius: unset
				border-bottom-right-radius: unset

	#content
		padding-inline: $gap-xs